<template>
    <label :for="id" :class="['input-radio-group', size]">
        <input type="radio" :id="id" :name="name" :value="value" :checked="checked" @change="onChange"
            :class="['input-radio']" />
        <span :class="['checkmark', description ? 'has-description' : 'no-description']"></span>
        <div class="input-radio-group__labeldesc">
            <div class="body-semibold-16">
                <slot></slot>
            </div>
            <span class="body-semibold-14" v-if="description">
                {{ description }}
            </span>
        </div>
    </label>
</template>

<script>
export default {
    name: "Radio",
    props: {
        id: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
        description: {
            type: String,
            default: "",
        },
        name: {
            type: String,
            default: "",
        },
        value: {
            type: String | Number,
            default: "",
        },
        checked: {
            type: Boolean,
            default: false,
        },
        size: {
            type: "medium" | "small",
            default: "medium",
        }
    },
    data() {
        return {

        }
    },
    methods: {
        onChange(e) {
            this.$emit("change", e.target.value);
        }
    },
};
</script>

<style lang="scss" scoped>
.has-description {
    top: 25%;
}

.no-description {
    top: 0%;
}

.input-radio-group {
    @include flex-row;
    gap: 0.75rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    padding-left: 1.95rem;


    label {
        margin: 0;
        padding: 0;
    }

    &__labeldesc {
        @include flex-column;
        gap: 0.25rem;
    }

    input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        appearance: none;
    }

    .checkmark {
        position: absolute;
        left: 0;
        top: 25%;
        width: 1.25rem;
        height: 1.25rem;
        border: 1px solid var(--maincolor);
        border-radius: 50%;
        transition: background-color 0.3s, border-color 0.3s;
        cursor: pointer;
        outline: none;
        margin: 0;
        padding: 0;

        &:checked {
            background-color: var(--maincolor);
            border-color: var(--maincolor);
        }

        &:disabled {
            cursor: not-allowed;
            background-color: var(--maincolor);
            border-color: var(--maincolor);
        }

        &:disabled:checked {
            background-color: var(--maincolor);
            border-color: var(--maincolor);
        }
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    input:checked~.checkmark:after {
        display: block;
    }

    .checkmark:after {
        top: 20%;
        left: 20%;
        width: 0.625rem;
        height: 0.625rem;
        border-radius: 50%;
        background: var(--maincolor);
    }

    &.small {
        .input-radio-group {
            padding-left: 1.75rem;
        }

        input[type="radio"] {
            width: 1rem;
            height: 1rem;
        }
    }
}
</style>